<template>
  <header id="header">
    <Nav />
  </header>
</template>

<script>
import Nav from "@/components/HeaderNav.vue";

export default {
  name: "Header",
  components: {
    Nav,
  },
  mounted() {
    const header = document.getElementById("header");
    const sticky = header.offsetTop;
    document.addEventListener("scroll", () => {
      const main = document.querySelector("main");
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        main.style.paddingTop = header.getBoundingClientRect().height + "px";
      } else {
        header.classList.remove("sticky");
        main.style.paddingTop = "0";
      }
    });
  },
};
</script>

<style lang="sass">
#header
  background-color: var(--primary)
  transition: background-color 750ms

  &.sticky
    z-index: 2
    position: fixed
    top: 0
    width: 100%
</style>
