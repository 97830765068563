<template>
  <div :class="{ dark: darkMode }" id="app">
    <Header />
    <router-view />
    <Footer />
    <div id="topbtn" class="hidden" @click="scrollToTop()">
      <i class="fas fa-chevron-up"></i>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  computed: {
    ...mapState({
      darkMode: "darkMode",
    }),
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  mounted() {
    const topBtn = document.querySelector("#topbtn");
    window.onscroll = () => {
      if (!window.scrollY) {
        topBtn.classList.add("hidden");
      } else {
        if (topBtn.classList.contains("hidden")) {
          topBtn.classList.remove("hidden");
        }
      }
    };
  },
};
</script>

<style lang="sass">
:root
  scroll-behavior: smooth
  --red: red
  --green: rgb(0, 185, 0)
  --blue: hsl(210, 100%, 50%)
  --primary: hsl(210, 100%, 50%)
  --secondary: hsl(55, 75%, 50%)
  --background: #FAFAFA
  --textColor: #2c3e50
  --skillColor: white
  --skillColorShadow: hsl(210, 100%, 50%)
  --jobTitle: hsl(210, 100%, 50%)

:root .dark
  --blue: hsl(210, 100%, 50%)
  --red: red
  --green: rgb(0, 185, 0)
  --primary: #444
  --secondary: hsl(55, 75%, 50%)
  --background: #333
  --textColor: white
  --skillColor: #383838
  --skillColorShadow: hsl(210, 100%, 50%)
  --jobTitle: hsl(210, 100%, 50%)

::-webkit-scrollbar
  width: 1em

::-webkit-scrollbar-track
  background: hsl(210, 100%, 40%)
  transition: all 750ms

::-webkit-scrollbar-thumb
  background: var(--primary)
  transition: all 750ms

::-webkit-scrollbar-thumb:hover
  background: hsl(210, 100%, 60%)
  transition: all 750ms

.dark
  ::-webkit-scrollbar-track
    background: #333

  ::-webkit-scrollbar-thumb
    background: #444

  ::-webkit-scrollbar-thumb:hover
    background: #555

@supports (scrollbar-color: hsl(210, 100%, 50%) hsl(210, 100%, 70%))
  *
    scrollbar-color: hsl(210, 100%, 60%) hsl(210, 100%, 70%)

body
  margin: 0

#app
  font-family: Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: var(--textColor)
  background-color: var(--background)
  transition: background-color 750ms

h2
  color: var(--blue)

main
  min-height: 100vh

#topbtn
  width: 40px
  height: 40px
  position: fixed
  bottom: 25px
  right: 10px
  background-color: var(--primary)
  border-radius: 10px
  box-shadow: 0 0 5px black
  color: white
  cursor: pointer
  transition: background-color 750ms

  i
    display: inherit
    position: relative
    top: 5%
    font-size: 2em

  &.hidden
    display: none

.loader
  display: inline-block
  width: 1em
  height: 1em
  border: 5px solid rgba(0, 128, 255, .25)
  border-left-color: var(--blue)
  border-radius: 50%
  background: transparent
  animation-name: rotate-loader
  animation-iteration-count: infinite
  animation-duration: 1s
  animation-timing-function: linear
  position: relative

@keyframes rotate-loader
  from
    transform: rotate(0)
  to
    transform: rotate(360deg)
</style>
