import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		darkMode: true,
	},
	mutations: {
		CHANGE_DARK_MODE(state, newMode) {
			state.darkMode = +newMode
			state.darkMode ? document.documentElement.classList.add('dark') : document.documentElement.classList.remove('dark')
			localStorage.setItem('darkMode', +newMode)
		},
	},
	actions: {
		changeDarkMode({ commit }, newMode) {
			commit('CHANGE_DARK_MODE', newMode)
		},
	},
	modules: {},
})

