<template>
  <nav>
    <router-link :to="{ name: 'Home' }" id="logo">
      <h1>
        <span>O</span>
        <span>G</span>
      </h1>
    </router-link>
    <div id="nav">
      <router-link :to="{ name: 'Home' }">Accueil</router-link> |
      <router-link :to="{ name: 'Bots' }">Bots</router-link> |
      <router-link :to="{ name: 'OpenClassrooms' }">Formation OC</router-link> |
      <router-link :to="{ name: 'Games' }">Jeux</router-link>
    </div>
    <div class="nav__options">
      <div @click="changeDarkMode(!darkMode)" class="mode-changer">
        <i :class="{ active: !darkMode }" class="fas fa-sun"></i>
        <i :class="{ active: darkMode }" class="fas fa-moon"></i>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState({
      darkMode: "darkMode",
    }),
  },
  methods: {
    ...mapActions(["changeDarkMode"]),
  },
};
</script>

<style lang="sass">
nav
  user-select: none
  padding: 0 25px 5px 10px
  display: flex
  justify-content: space-between
  align-items: center
  color: white

  #logo
    text-decoration: none
    font-family: 'Saira Stencil One', Avenir, Helvetica, Arial, sans-serif

    & > h1
      margin: 0
      font-size: 3rem

      > span:nth-child(1)
        color: red

      > span:nth-child(2)
        color: var(--secondary)

  #nav
    font-weight: bold
    a
      margin: 0 5px
      text-decoration: none
      color: inherit

      &.router-link-exact-active
        color: red

  .nav__options
    display: flex
    column-gap: 10px

  .fa-user
    cursor: pointer
    color: white
    font-size: 1.5rem

  .mode-changer
    cursor: pointer
    font-size: 1.5rem
    color: var(--secondary)
    display: flex
    align-items: center

    > *
      transition: transform 750ms, opacity 750ms
      opacity: 0

      &.active
        transform: rotate(720deg)
        opacity: 1

    > :nth-child(2)
      position: absolute

  @media screen and (min-width: 992px)
    #nav
      margin-left: -30px
</style>
