<template>
  <main>
    <h2>Bots développés</h2>
    <p>
      Plus d'informations disponibles bientôt, avec normalement la possibilité
      d'interagir avec certains bots !
    </p>
    <section>
      <router-link :to="{ name: 'ECSBot' }">
        <div>
          <h3>ECSBot</h3>
          <p>
            Bot Discord multifonction développé pour l'association Esport Club
            Strasbourg
          </p>
          <p>État : <span class="stopped">Développement arrêté</span></p>
        </div>
      </router-link>
      <router-link to="">
        <div>
          <h3>Activity Creation</h3>
          <p>
            Bot Discord permettant aux utilisateurs de pouvoir mieux s'organiser
            pour faire des activités
          </p>
          <p>État : <span class="stopped">Développement arrêté</span></p>
        </div>
      </router-link>
      <router-link to="">
        <div>
          <h3>OgniBot</h3>
          <p>Bot Twitch pour calculer le watchtime des viewers d'un streamer</p>
          <p>État : <span class="stopped">Développement arrêté</span></p>
        </div>
      </router-link>
    </section>
  </main>
</template>

<script>
export default {
  name: "Bots",
  metaInfo() {
    return {
      title: "Bots - ogniter.fr",
      meta: [
        {
          name: "description",
          content: "Page concernant les bots faits par Melody - ogniter.fr",
        },
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
main > section
  width: 95%
  margin: auto

  > *
    text-decoration: none

    > *
      margin: 25px 0
      border: 2px solid var(--red)
      border-radius: 25px
      text-decoration: none
      color: var(--textColor)
      background-color: var(--skillColor)
      transition: color 750ms, background-color 750ms

      .active
        color: hsl(157, 87%, 39%)

        .developing
          color: var(--secondary)

        .stopped
          color: hsl(0, 61%, 50%)

  @media screen and (min-width: 992px)
    main > section
      width: 50%
</style>
