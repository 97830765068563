<template>
  <main>
    <h2>Projets de la Formation OpenClassrooms</h2>
    <p>Cliquer sur un projet pour aller à la page github</p>
    <section>
      <h2>Formation Développeuse Web</h2>
      <a href="https://github.com/OgniMelo/P2" target="_blank">
        <div>
          <h3>Projet 2 - Booki</h3>
          <p>
            Réalisation : Prototype d'un site de réservation d'hébergements
            développé à partir d'une maquette
          </p>
        </div>
      </a>
      <a href="https://github.com/OgniMelo/P3" target="_blank">
        <div>
          <h3>Projet 3 - Ohmyfood</h3>
          <p>Réalisation : Prototype d'un site de réservation de menus</p>
        </div>
      </a>
      <a href="https://github.com/OgniMelo/P4" target="_blank">
        <div>
          <h3>Projet 4 - La Chouette Agence</h3>
          <p>Réalisation : Optimisation du SEO du site d'une agence web</p>
        </div>
      </a>
      <a href="https://github.com/OgniMelo" target="_blank">
        <div>
          <h3>Projet 5 - Kanap</h3>
          <p>
            Réalisation : Site e-commerce avec intégration des éléments en
            Javascript
          </p>
        </div>
      </a>
      <a href="https://github.com/OgniMelo" target="_blank">
        <div>
          <h3>Projet 6 - Hot Takes</h3>
          <p>Réalisation : API d'un site d'avis gastronomique avec Node.js</p>
        </div>
      </a>
      <a href="https://github.com/OgniMelo/P7" target="_blank">
        <div>
          <h3>Projet 7 - Groupomania</h3>
          <p>
            Réalisation : Réseau social type Reddit/9GAG avec Vue.js
            (Front)/Node.js (Back)
          </p>
        </div>
      </a>
    </section>
    <section>
      <h2>Formation React</h2>
      <a href="https://github.com/OgniMelo/React_P4" target="_blank">
        <div>
          <h3>Projet 4 - GameOn</h3>
          <p>Réalisation : Création d'une landing page avec Javascript</p>
        </div>
      </a>
      <a href="https://github.com/OgniMelo/React_P6" target="_blank">
        <div>
          <h3>Projet 6 - FishEye</h3>
          <p>
            Réalisation : Création d'un site listant des photographes et leurs
            œuvres
          </p>
        </div>
      </a>
      <a href="https://github.com/OgniMelo/React_P7" target="_blank">
        <div>
          <h3>Projet 7 - Les Petits Plats</h3>
          <p>
            Réalisation : Création d'un site de recettes avec filtres et barre
            de recherche
          </p>
        </div>
      </a>
    </section>
  </main>
</template>

<script>
export default {
  name: "OC",
  metaInfo() {
    return {
      title: "Formation OpenClassrooms - ogniter.fr",
      meta: [
        {
          name: "description",
          content:
            "Liste des projets effectués lors de la formation Développeur Web OpenClassrooms - ogniter.fr",
        },
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
main > section
  width: 95%
  margin: auto

  > h2
    width: fit-content
    margin: auto

  > a
    text-decoration: none

    > div
      user-select: none
      margin: 25px 0
      color: var(--textColor)
      border: 2px solid var(--red)
      border-radius: 25px
      background-color: var(--skillColor)
      transition: color 750ms, background-color 750ms

@media screen and (min-width: 992px)
  main > section
    width: 50%
</style>
