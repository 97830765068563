<template>
  <div class="portfolio">
    <header class="portfolio__header">
      <nav>
        <a @click="scrollTo('intro')">Intro</a> |
        <a @click="scrollTo('skills')">Compétences</a> |
        <a @click="scrollTo('works')">Réalisations</a> |
        <a @click="scrollTo('experience')">Expérience</a> |
        <a @click="scrollTo('contact')">Contact</a> |
        <a href="/cv.pdf" target="_blank">CV</a>
      </nav>
    </header>
    <main class="portfolio__main">
      <section id="home">
        <h2 class="home__title">Développeuse Fullstack</h2>
        <p class="home__text">
          {{ name }}, développeuse de 25 ans passionée par le développement
        </p>
        <img class="home__img" src="@/assets/avatar.jpg" alt="Avatar" />
      </section>
      <section ref="intro" id="intro">
        <h2 class="intro__title">Hello, je suis {{ name }}, enchantée !</h2>
        <p class="intro__text">
          Passionnée par le développement depuis le lycée, je passe mon temps
          libre à créer de nouvelles choses comme des bots, des applications et
          des jeux, me permettant ainsi de développer mes compétences.
        </p>
        <p class="intro__text">
          Que ce soit de petits projets pour tester et découvrir de nouvelles
          fonctionnalités, aux plus gros projets demandant plus de
          connaissances, je prends du plaisir à coder dans le but de donner vie
          à ces projets.
        </p>
        <p class="intro__text">
          Bien qu'ayant une préférence pour le back-end, je suis tout aussi
          capable de travailler sur le front-end d'un site 🙂
        </p>
      </section>
      <section ref="skills" id="skills">
        <h2>Compétences</h2>
        <div class="skills__front">
          <h3>Front-End</h3>
          <div>
            <div
              class="skill"
              v-for="skill in skills.front"
              :key="skill.language"
            >
              <a :href="skill.link">
                <div>
                  <img :src="skill.logo" :alt="skill.logoAlt" />
                  <p>{{ skill.language }}</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="skills__back">
          <h3>Back-End</h3>
          <div>
            <div
              class="skill"
              v-for="skill in skills.back"
              :key="skill.language"
            >
              <a :href="skill.link">
                <div>
                  <img :src="skill.logo" :alt="skill.logoAlt" />
                  <p>{{ skill.language }}</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="skills__tools">
          <h3>Outils</h3>
          <div>
            <div class="skill" v-for="tool in skills.tools" :key="tool.name">
              <a :href="tool.link">
                <div>
                  <img :src="tool.logo" :alt="tool.logoAlt" />
                  <p>{{ tool.name }}</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section ref="works" id="works">
        <h2>Réalisations</h2>
        <div class="project__list">
          <div class="project" v-for="project in works" :key="project.name">
            <h3 class="project__title">{{ project.name }}</h3>
            <div class="separation"></div>
            <div class="project__content">
              <div v-if="project.screen" class="project__image">
                <img :src="project.screen" :alt="project.altScreen" />
              </div>
              <div class="project__bg">
                <div class="project__details">
                  <div class="project__details__content">
                    <p class="project__details__content__objective">
                      Objectif : {{ project.objective }}
                    </p>
                    <p class="project__details__content__techno">
                      Technologies : {{ project.technologies.join(", ") }}.
                    </p>
                  </div>
                  <div class="project__details__links">
                    <a v-if="project.url" :href="project.url">
                      <button>
                        <i class="fa fa-external-link-alt"></i>Site
                      </button>
                    </a>
                    <a v-if="project.github" :href="project.github">
                      <button><i class="fab fa-github"></i>Github</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section ref="projects" id="projects">
        <h2>Projets de formation OpenClassrooms</h2>
        <div class="project__list">
          <div class="project" v-for="project in projects" :key="project.name">
            <h3 class="project__title">{{ project.name }}</h3>
            <div class="separation"></div>
            <div class="project__content">
              <div v-if="project.screen" class="project__image">
                <img :src="project.screen" :alt="project.altScreen" />
              </div>
              <div class="project__bg">
                <div class="project__details">
                  <div class="project__details__content">
                    <p class="project__details__content__objective">
                      Objectif : {{ project.objective }}
                    </p>
                    <p class="project__details__content__techno">
                      Technologies : {{ project.technologies.join(", ") }}.
                    </p>
                  </div>
                  <div class="project__details__links">
                    <a v-if="project.url" :href="project.url">
                      <button>
                        <i class="fa fa-external-link-alt"></i>Site
                      </button>
                    </a>
                    <a v-if="project.github" :href="project.github">
                      <button><i class="fab fa-github"></i>Github</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section ref="experience" id="experience">
        <section id="formations">
          <h2>Formation</h2>
          <div class="formations__list">
            <div
              class="formation"
              v-for="formation in formations"
              :key="formation.id"
            >
              <h3 class="formation__institution">
                {{ formation.institution }}
              </h3>
              <p class="formation__dates">{{ formation.dates }}</p>
              <p class="formation__description">{{ formation.description }}</p>
            </div>
          </div>
        </section>
        <section id="experiences">
          <h2>Expérience professionnelle</h2>
          <div class="experiences__list">
            <div
              class="experience"
              v-for="experience in experiences"
              :key="experience.id"
            >
              <h3 class="experience__title">{{ experience.title }}</h3>
              <h4 class="experience__organization">
                {{ experience.organization }}
              </h4>
              <p class="experience__dates">{{ experience.dates }}</p>
              <p
                class="experience__description"
                v-for="description in experience.description"
                :key="description"
              >
                - {{ description }}
              </p>
            </div>
          </div>
        </section>
      </section>
    </main>
    <footer class="portfolio__footer">
      <section ref="contact" id="contact">
        <h2>Me contacter</h2>
        <form action="https://usebasin.com/f/a88438687c7e" method="post">
          <div class="form__name">
            <label for="name">Nom</label>
            <input name="name" type="text" required />
          </div>
          <div class="form__email">
            <label for="email">Email</label>
            <input name="email" type="email" required />
          </div>
          <div class="form__subject">
            <label for="subject">Sujet</label>
            <input name="subject" type="text" required />
          </div>
          <div class="form__message">
            <label for="message">Message</label>
            <textarea name="message" required></textarea>
          </div>
          <button type="submit">Envoyer</button>
        </form>
      </section>
      <section id="links">
        <div class="link" v-for="link in links" :key="link.hoverText">
          <a :href="link.link">
            <img :src="link.image" :alt="link.imageAlt" />
          </a>
          <p>{{ link.username }}</p>
        </div>
      </section>
    </footer>
  </div>
</template>

<script>
import {
  works,
  projects,
  skills,
  formations,
  experiences,
  links,
} from "@/modules/portfolioVars.js";

export default {
  name: "Home",
  data() {
    return {
      name: "Melody",
      works,
      projects,
      skills,
      formations,
      experiences,
      links,
    };
  },
  computed: {
    getCurrentYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    scrollTo(ref) {
      const elem = this.$refs[ref];
      elem.scrollIntoView();
    },
  },
  async mounted() {
    const header = document.querySelector(".portfolio__header");

    const mainHeader = document.getElementById("header");
    if (document.body.clientWidth > 991)
      header.style.marginTop = -header.getBoundingClientRect().height + "px";
    header.style.top =
      mainHeader.getBoundingClientRect().height < 80
        ? "80px"
        : mainHeader.getBoundingClientRect().height + "px";
  },
  metaInfo() {
    return {
      title: "Accueil - ogniter.fr",
      meta: [
        {
          name: "description",
          content: "Portfolio de Melody 'Ogniter' Androny - ogniter.fr",
        },
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
.portfolio
  &__header
    z-index: 3
    position: fixed
    width: 100%

    nav
      padding: 0
      display: flex
      justify-content: center
      align-items: center
      flex-wrap: wrap
      background-color: var(--primary)
      font-weight: bold
      transition: background-color 750ms

      a
        cursor: pointer
        margin: 2px 7.5px
        text-decoration: none
        color: white

        &:hover
          text-decoration: underline

    &__main
      margin-top: 50px

  main > section, footer > section
    padding: 100px 10px

    > h2
      margin-bottom: 3em

  #home
    padding-top: 0
    > *
      margin-bottom: 1.75rem

  .home
    &__text
      font-size: 1.25em

    &__img
      border-radius: 25px

  #intro
    color: white
    background-color: var(--primary)
    transition: background-color 750ms
    > h2
      color: white

  .intro__text
    margin: 10px auto
    font-size: 1.25em

  #skills
    > div
      margin-bottom: 3em

      > div
        display: flex
        justify-content: space-evenly
        align-items: center
        flex-wrap: wrap
        row-gap: 2em

  .skill
    margin: 0 10px

    > a
      color: inherit
      text-decoration: none
      border-radius: 25px

      > div
        user-select: none
        width: 100px
        height: 100px
        padding: 10px
        background-color: var(--skillColor)
        border-radius: 25px
        box-shadow: 0 0 5px black
        transition: background-color 750ms

        &:hover
          box-shadow: 0 0 10px var(--skillColorShadow)

        > img
          width: 100%
          height: 90%
          object-fit: contain

        > p
          display: flex
          justify-content: center
          align-items: center
          width: 100%
          height: 10%
          font-weight: bold
          margin: 0

  .separation
    width: 33%
    height: 5px
    border-radius: 25px
    background-color: var(--jobTitle)
    margin-bottom: 2em
    transition: background-color 750ms

  .project
    min-height: 450px
    margin-bottom: 5em
    display: flex
    flex-direction: column
    align-items: center

    &__list
      display: flex
      justify-content: space-between
      flex-wrap: wrap

    &__title
      display: flex
      justify-content: center
      align-items: center

    &__content
      width: 100%
      height: 100%
      position: relative
      z-index: 0

    &__image
      width: 100%
      height: 100%
      display: flex
      justify-content: center
      align-items: center

      > img
        max-width: 100%
        max-height: 100%
        object-fit: contain

    &__bg
      user-select: none
      width: 100%
      height: 100%
      position: absolute
      top: 0
      left: 0
      opacity: 0
      background-color: rgba(0, 0, 0, .85)
      transition: opacity 750ms

      &:hover
        opacity: 1

    &__details
      width: 100%
      height: 100%
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      color: white

      &__content
        margin-bottom: 1.5em

      &__links
        width: 75%
        display: flex
        justify-content: space-evenly

        button
          cursor: pointer
          width: 100px
          height: 40px
          border: none
          border-radius: 20px
          background-color: var(--primary)
          font-size: 1em
          color: white
          transition: background-color 750ms

          &:hover
            filter: brightness(1.1)

          > i
            margin-right: 5px

  #experience
    display: flex
    flex-direction: column

    > section
      flex: 1

  #formations, #experiences
    > h2
      margin-bottom: 3em

  .formations, .experiences

    &__list, &__list
      margin: 0 2%
      text-align: left
      border-left: 3px dashed var(--secondary)
      padding-left: 25px
      transition: border-left 750ms

  .formation, .experience
    margin-bottom: 2em

    &::before, &::before
      content: ""
      margin-right: 16px
      margin-left: -36px
      padding: 1.5px 10px
      border-radius: 25px
      background-color: var(--jobTitle)
      transition: background-color 750ms

    &__institution, &__title
      display: inline
      color: var(--jobTitle)
      font-size: 1.25em
      font-weight: bold
      transition: color 750ms

    &__dates, &__dates
      margin: .5em 0
      font-style: italic

    &__description, &__description
      margin: .25em 0

  .experience__organization
    margin: .5em 0
    font-size: 1.1em
    font-weight: bold

  #contact
    > h2
      color: white
    > form
      display: flex
      flex-direction: column
      align-items: center
      text-align: left

      > div
        width: 90%
        margin-bottom: 1em
        display: flex
        flex-direction: column

    input
      min-height: 25px
      margin-top: 5px
      padding: 0 5px
      border-radius: 10px
      border: none
      outline: none

    textarea
      min-height: 100px
      margin-top: 5px
      padding: 5px
      border-radius: 10px
      border: none
      outline: none
      resize: vertical

    button
      width: 25%
      height: 35px
      color: var(--primary)
      font-weight: bold
      cursor: pointer
      border: none
      border-radius: 25px
      background-color: white
      transition: color 750ms

  #links
    display: flex
    justify-content: center
    align-items: center
    flex-wrap: wrap

  .link
    position: relative
    user-select: none
    height: 75px
    padding: 10px
    border-radius: 25px

    > a
      color: inherit
      text-decoration: none

      > img
        width: 100%
        height: 50%
        object-fit: contain

    > p
      user-select: text
      margin: 0
      font-weight: bold

  footer
    background-color: var(--primary)
    text-align: center
    color: white
    transition: background-color 750ms

    p
      margin: 0
      padding: 1em 0

    > section:nth-child(1)
      padding: 100px 10px 0 0

    > section:nth-child(2)
      padding: 30px 10px

  @media screen and (min-width: 992px)
    .portfolio__header nav
      flex-direction: row
    #intro
      width: 75%
      margin: auto
      border-radius: 25px

    .intro__text
      width: 75%

    #skills > div > div
      justify-content: center

    .skill > a > div
      width: 125px
      height: 125px

    .project
      width: 48.5%

    #experience
      flex-direction: row

    .formations__list, .experiences__list
      margin: 0 10%

    #contact > form > div
      width: 25%
</style>
