<template>
  <main>
    <h2>Liste des jeux</h2>
    <div class="game__list">
      <div class="game__element">
        <router-link :to="{ name: 'Snake' }">
          <h3>Snake</h3>
        </router-link>
      </div>
      <div class="game__element">
        <a href="#">
          <h3>Centipede - (A venir peut-être)</h3>
        </a>
      </div>
      <div class="game__element">
        <a href="#">
          <h3>Pac-Man - (A venir peut-être)</h3>
        </a>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "Games",
  metaInfo() {
    return {
      title: "Jeux - ogniter.fr",
      meta: [
        { name: "description", content: "Liste des jeux du site - ogniter.fr" },
      ],
    };
  },
};
</script>

<style lang="sass">
.game
  &__list
    width: 75vw
    margin: 50px auto
    padding: 25px
    border-radius: 10px
    box-shadow: 0 0 15px var(--primary)
    display: flex
    flex-wrap: wrap
    justify-content: space-around
    gap: 10px
    transition: box-shadow 750ms

    &__element
      overflow: hidden
      width: 150px
      height: 150px
      display: flex
      flex-direction: column
      justify-content: space-evenly
      align-items: center
      border: 2px solid var(--red)
      border-radius: 25px
      background-color: var(--skillColor)
      transition: background-color 750ms

      > a
        width: 100%
        height: 100%
        display: flex
        justify-content: center
        align-items: center
        color: inherit
        transition: color 750ms

        > h3
          margin: 0
</style>
