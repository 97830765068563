const skills = {
	front: [
		{
			language: 'Vue.js',
			logo: './images/logos/logo_vue.svg',
			logoAlt: 'Logo Vue.js',
			link: 'https://vuejs.org/',
		},
		{
			language: 'React',
			logo: './images/logos/logo_react.svg',
			logoAlt: 'Logo React',
			link: 'https://fr.reactjs.org/',
		},
		{
			language: 'Javascript',
			logo: './images/logos/logo_js.svg',
			logoAlt: 'Logo Javascript',
			link: 'https://developer.mozilla.org/fr/docs/Web/JavaScript',
		},
		{
			language: 'HTML5',
			logo: './images/logos/logo_html.svg',
			logoAlt: 'Logo HTML5',
			link: 'https://developer.mozilla.org/fr/docs/Web/HTML',
		},
		{
			language: 'CSS3',
			logo: './images/logos/logo_css.svg',
			logoAlt: 'Logo CSS3',
			link: 'https://developer.mozilla.org/fr/docs/Web/CSS',
		},
		{
			language: 'SASS',
			logo: './images/logos/logo_sass.svg',
			logoAlt: 'Logo SASS',
			link: 'https://sass-lang.com/',
		},
	],
	back: [
		{
			language: 'Node.js',
			logo: './images/logos/logo_nodejs.svg',
			logoAlt: 'Logo Node.js',
			link: 'https://nodejs.org/',
		},
		{
			language: 'Express.js',
			logo: './images/logos/logo_expressjs.png',
			logoAlt: 'Logo Express.js',
			link: 'https://expressjs.com/',
		},
		{
			language: 'API Rest',
			logo: './images/logos/logo_apirest.svg',
			logoAlt: 'Logo API Rest',
			link: '',
		},
		{
			language: 'Discord.js',
			logo: './images/logos/logo_discordjs.svg',
			logoAlt: 'Logo Discord.js',
			link: 'https://discord.js.org/',
		},
		{
			language: 'MySQL',
			logo: './images/logos/logo_mysql.svg',
			logoAlt: 'Logo MySQL',
			link: 'https://www.mysql.com/',
		},
		{
			language: 'MongoDB',
			logo: './images/logos/logo_mongodb.svg',
			logoAlt: 'Logo MongoDB',
			link: 'https://www.mongodb.com/',
		},
		{
			language: 'Redis',
			logo: './images/logos/logo_redis.svg',
			logoAlt: 'Logo Redis',
			link: 'https://redis.io/',
		},
		{
			language: 'Sequelize',
			logo: './images/logos/logo_sequelize.png',
			logoAlt: 'Logo Sequelize',
			link: 'https://sequelize.org/',
		},
	],
	tools: [
		{
			name: 'VSCode',
			logo: './images/logos/logo_vscode.svg',
			logoAlt: 'Logo VSCode',
			link: 'https://code.visualstudio.com/',
		},
		{
			name: 'Git / GitHub',
			logo: './images/logos/logo_github.svg',
			logoAlt: 'Logo GitHub',
			link: 'https://github.com/',
		},
		{
			name: 'UltraEdit',
			logo: './images/logos/logo_ultraedit.png',
			logoAlt: 'Logo UltraEdit',
			link: 'https://www.ultraedit.com/',
		},
		{
			name: 'FileZilla',
			logo: './images/logos/logo_filezilla.svg',
			logoAlt: 'Logo FileZilla',
			link: 'https://filezilla-project.org/',
		},
		{
			name: 'Debian',
			logo: './images/logos/logo_debian.svg',
			logoAlt: 'Logo Debian',
			link: 'https://www.debian.org/index.fr.html',
		},
		{
			name: 'Apache',
			logo: './images/logos/logo_apache.svg',
			logoAlt: 'Logo Apache',
			link: 'https://httpd.apache.org/',
		},
	],
}

const works = [
	{
		name: 'Garage Land Rover Gland',
		url: 'https://land-gland.ch',
		screen: './images/landgland.png',
		altScreen: 'Aperçu du projet Garage Land Rover Gland',
		objective: "Refonte complète du site, passage d'un CMS à React.",
		technologies: ['React', 'Node.js', 'Express.js', 'MongoDB'],
	},
	{
		name: 'ECSBot',
		screen: './images/ecsbot.png',
		altScreen: 'Aperçu du projet ECSBot',
		objective: "Création d'un bot Discord multifonction pour l'association Esport Club Strasbourg.",
		technologies: ['Node.js', 'Discord.js', 'Express.js', 'MySQL', 'MongoDB', 'Redis'],
	},
	{
		name: 'Sheep Boom-Bah',
		screen: './images/sbb.png',
		altScreen: 'Aperçu du projet Sheep Boom-Bah',
		objective: "Reproduction d'un jeu de société, en version web.",
		technologies: ['React', 'Node.js', 'Express.js', 'MongoDB', 'Redis'],
	},
]

const projects = [
	{
		name: 'Les Petits Plats',
		github: 'https://github.com/OgniMelo/React_P7',
		url: 'https://ogniter.fr/OpenClassrooms/React/p7',
		screen: './images/react_p7.png',
		altScreen: 'Aperçu du projet Les Petits Plats',
		objective: "Création d'un site de recettes avec filtres et barre de recherche.",
		technologies: ['Javascript', 'HTML', 'CSS'],
	},
	{
		name: 'FishEye',
		github: 'https://github.com/OgniMelo/React_P6',
		url: 'https://ogniter.fr/OpenClassrooms/React/p6',
		screen: './images/react_p6.png',
		altScreen: 'Aperçu du projet FishEye',
		objective: "Création d'un site listant des photographes et leurs œuvres.",
		technologies: ['Javascript', 'HTML', 'CSS'],
	},
	{
		name: 'GameOn',
		github: 'https://github.com/OgniMelo/React_P4',
		url: 'https://ogniter.fr/OpenClassrooms/React/p4',
		screen: './images/react_p4.png',
		altScreen: 'Aperçu du projet GameOn',
		objective: "Création d'une landing page avec Javascript.",
		technologies: ['Javascript', 'HTML', 'CSS'],
	},
	{
		name: 'Groupomania',
		github: 'https://github.com/OgniMelo/P7',
		url: 'https://groupomania.ogniter.fr/',
		screen: './images/p7.png',
		altScreen: 'Aperçu du projet Groupomania',
		objective: "Création d'un réseau social type Reddit/9GAG.",
		technologies: ['Vue.js', 'Javascript', 'HTML', 'SASS', 'Node.js', 'Express.js', 'MySQL', 'Sequelize'],
	},
	{
		name: 'Hot Takes',
		url: 'https://ogniter.fr/Openclassrooms/WebDev/p6/',
		screen: './images/p6.png',
		altScreen: 'Aperçu du projet Hot Takes',
		objective: "Création de l'API d'un site d'avis gastronomique.",
		technologies: ['Node.js', 'Express.js', 'MongoDB'],
	},
	{
		name: 'Kanap',
		url: 'https://ogniter.fr/Openclassrooms/WebDev/p5/',
		screen: './images/p5.png',
		altScreen: 'Aperçu du projet Kanap',
		objective: "Intégration d'éléments avec Javascript dans un site d'e-commerce.",
		technologies: ['Javascript'],
	},
	{
		name: 'Ohmyfood',
		github: 'https://github.com/OgniMelo/P3',
		url: 'https://ogniter.fr/Openclassrooms/WebDev/p3/',
		screen: './images/p3.png',
		altScreen: 'Aperçu du projet Ohmyfood',
		objective: "Développement de prototype d'un site de réservation de menus, avec des animations.",
		technologies: ['HTML', 'CSS'],
	},
	{
		name: 'Booki',
		github: 'https://github.com/OgniMelo/P2',
		url: 'https://ogniter.fr/Openclassrooms/WebDev/p2/',
		screen: './images/p2.png',
		altScreen: 'Aperçu du projet Booki',
		objective: "Développement de prototype d'un site de réservation d'hébergements à partir d'une maquette.",
		technologies: ['HTML', 'CSS'],
	},
]

const formations = [
	{
		id: 4,
		institution: 'OpenClassrooms',
		dates: 'Octobre 2022 - Actuellement',
		description: "Formation Développeur d'Application Javascript React (RNCP 6)",
	},
	{
		id: 3,
		institution: 'OpenClassrooms',
		dates: 'Octobre 2021 - Janvier 2022',
		description: 'Formation Développeur Web (RNCP 5)',
	},
	{
		id: 2,
		institution: 'EPITECH, Le Kremlin-Bicêtre (94)',
		dates: '2016 - 2017',
		description: '(Diplôme non obtenu)',
	},
	{
		id: 1,
		institution: "IUT d'Orsay, Orsay (91)",
		dates: '2014 - 2016',
		description: 'DUT Informatique (Diplôme non obtenu)',
	},
]

const experiences = [
	{
		id: 6,
		title: 'Technicienne informatique',
		organization: 'Safari Technologies',
		dates: 'Juillet 2022 - Septembre 2022',
		description: [''],
	},
	{
		id: 5,
		title: 'Administratrice Discord / Développeuse',
		organization: 'Esport Club Strasbourg',
		dates: 'Juin 2020 - Juillet 2022',
		description: ['Développement du bot Discord multifonction ECSBot', 'Gestion du serveur, des utilisateurs, des droits'],
	},
	{
		id: 4,
		title: 'Technicienne Helpdesk',
		organization: 'Antares',
		dates: 'Juin 2020 - Décembre 2020',
		description: ['Création/Suppression de comptes Exchange', 'Environnement Windows 7-10, Office 2010-365'],
	},
	{
		id: 3,
		title: 'Superviseure Service Desk',
		organization: 'Brainlogic',
		dates: 'Juillet 2019 - Février 2020',
		description: ["Mise en place du planning d'affectation des tâches", 'Extraction des statistiques des techniciens', 'Rédaction de rapports journaliers/hebdomadaires', 'Gestion des crises et communication groupe'],
	},
	{
		id: 2,
		title: 'Technicienne Helpdesk',
		organization: 'Brainlogic',
		dates: 'Avril 2019 - Juin 2019',
		description: ['Création/Suppression de droits/comptes AD', 'Environnement Windows 7-10, Office 365'],
	},
	{
		id: 1,
		title: 'Technicienne Helpdesk',
		organization: 'Econocom',
		dates: 'Juillet 2019 - Février 2020',
		description: ["Prise en charge et résolution d'incidents à distance, moyenne de 60 appels par jour, 75% FCR", 'Création de tickets, analyse du besoin utilisateurs', 'Environnement Windows 7, Office 2007-2010'],
	},
]

const links = [
	{
		link: 'https://discord.com/',
		image: './images/logos/logo_discord.svg',
		imageAlt: 'Discord',
		username: 'Melody#3000',
	},
	{
		link: 'https://www.linkedin.com/in/melody-androny/',
		image: './images/logos/logo_linkedin.svg',
		imageAlt: 'Profil LinkedIn',
		username: 'melody-androny',
	},
]

export { works, projects, skills, formations, experiences, links }
