<template>
  <footer id="footer">
    <p>Fait avec 💙 sans CMS</p>
    <p>
      Copyright Melody 'Ogniter' {{ getCurrentYear }}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 480">
        <path fill="#5BCEFA" d="M0 0h800v480H0z" />
        <path fill="#F5A9B8" d="M0 96h800v288H0z" />
        <path fill="#FFF" d="M0 192h800v96H0z" />
      </svg>
    </p>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    getCurrentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="sass">
#footer
  padding: 5px 0
  background-color: var(--primary)
  color: white
  transition: background-color 750ms

  p
    margin: 0

    svg
      display: inline
      width: 20px
</style>
