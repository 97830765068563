import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import PageNotFound from '@/views/PageNotFound.vue'
import Portfolio from '@/views/Portfolio.vue'
import Bots from '@/views/Bots.vue'
import OpenClassrooms from '@/views/OpenClassrooms.vue'
import Games from '@/views/Games.vue'
import Snake from '@/views/Snake.vue'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
	{
		path: '*',
		name: 'PageNotFound',
		component: PageNotFound,
	},
	{
		path: '/',
		name: 'Home',
		component: Portfolio,
	},
	{
		path: '/bots',
		name: 'Bots',
		component: Bots,
	},
	{
		path: '/openclassrooms',
		name: 'OpenClassrooms',
		component: OpenClassrooms,
	},
	{
		path: '/jeux',
		name: 'Games',
		component: Games,
	},
	{
		path: '/jeux/snake',
		name: 'Snake',
		component: Snake,
	},
	// {
	// path: '/about',
	// name: 'About',
	// route level code-splitting
	// this generates a separate chunk (about.[hash].js) for this route
	// which is lazy-loaded when the route is visited.
	// component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	// },
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

import store from '@/store/index'

router.beforeEach(async (to, from, next) => {
	if (to.name !== from.name) window.scrollTo({ top: 0, behavior: 'instant' })
	let darkMode = localStorage.getItem('darkMode')
	if (darkMode != 0 && darkMode != 1) darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches ? 1 : 0
	store.commit('CHANGE_DARK_MODE', darkMode)
	if (!to.matched.length) return next({ name: 'PageNotFound' })
	next()
})

export default router

